import { gql } from '@apollo/client';

export const FETCH_API_LIST = gql`
  query fetchApiList($type: ID!) {
    apiList(type: $type)
      @rest(type: "[ApiListItem]", path: "/v4/lists?type={args.type}") {
      id
      name
      key
      label
    }
  }
`;
