import 'twin.macro';
import React, { useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { IMPORT_VACANCY } from 'graphql/vacancies/mutations';
import { useMutation } from 'hooks/sympl-mutation';
import Input from 'components/form/input/Input';
import Label from 'components/form/Label';
import Button from 'components/button/Button';
import { Error, Footnote } from 'components/typography/Typography';
import Checkbox from 'components/form/checkbox/Checkbox';
import Dropdown from 'components/dropdown/Dropdown';
import useNavigationContext from 'hooks/context/nav-context';
import { ToastTypes } from 'types/notificationTypes';
import { useToastNotifications } from 'hooks/notificationHooks';
import { useSymplCookie } from 'hooks/symplCookie';
import { BrandModal, ModalType } from 'views/settings/brands/BrandModal';
import { CREATE_BRAND } from 'graphql/brands/mutations';
import SpinningSymplLoader from 'components/loading/spinningSymplLoader';
import { fireEvent } from 'utils/eventHelper';

type ImportCampaignFormProps = {
  onCreate?: (vacancyId: number) => void;
};

const ImportCampaignForm: React.FC<ImportCampaignFormProps> = ({
  onCreate,
}) => {
  const { errors, control, handleSubmit, reset: resetForm } = useForm();
  const [importVacancy, { loading }] = useMutation(IMPORT_VACANCY);
  const { brands, refetchBrands } = useNavigationContext();
  const { addToast } = useToastNotifications();
  const [viewedSurveys, setViewedSurveys] =
    useSymplCookie<number[]>('viewed-surveys');
  const [brandModalShown, setBrandModalShown] = useState<boolean>(false);

  const mappedBrands = useMemo(() => {
    return brands?.map((b) => ({
      key: b.id,
      label: b.name,
    }));
  }, [brands]);

  const [createBrand] = useMutation(CREATE_BRAND);

  const onSubmit = (data: {
    'vacancy-url': string;
    'vacancy-enable-spend': boolean;
    'vacancy-brand': number;
  }) => {
    const url = data['vacancy-url'].split('?')[0];

    importVacancy({
      variables: {
        input: {
          url: url,
          enable_spend: data['vacancy-enable-spend'],
          brand_id: data['vacancy-brand'],
        },
      },
    })
      .then((res) => {
        if (!res.data || res.data.success === false || res.data.errors) return;

        fireEvent('import_campaign_success', {
          url,
        });

        if (res.data?.importVacancy) {
          const vacancyId = res.data.importVacancy.id;
          setViewedSurveys(
            viewedSurveys ? [...viewedSurveys, vacancyId] : [vacancyId]
          );
          onCreate?.(vacancyId);
        }

        addToast({
          type: ToastTypes.SUCCESS,
          description: 'We successfully imported your vacancy.',
        });

        resetForm();
      })
      .catch(() => {
        addToast({
          type: ToastTypes.ERROR,
          description: 'We could not import your vacancy. Please try again.',
        });
        fireEvent('import_campaign_fail', {
          url,
        });
      });
  };

  if (loading) {
    <div tw="flex flex-col overflow-y-auto overflow-x-hidden no-scrollbar px-1">
      <SpinningSymplLoader
        messages={[
          'We are looking for your vacancy...',
          'Found it!',
          "Let's gather the important details...",
          'Got it! Time to import...',
          'Importing the vacancy now...',
          'Almost there...',
          "We're finishing up...",
        ]}
        loading={loading}
        delay={2800}
      />
    </div>;
  }

  return (
    <div tw="flex flex-col overflow-y-auto overflow-x-hidden no-scrollbar px-1">
      <form
        id="import-vacancy-form"
        tw="flex flex-col min-w-[25rem] h-[410px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form__body" tw="w-full h-full grow">
          <div tw="mt-4 w-full">
            <Label htmlFor="vacancy-url">Vacancy URL*</Label>
            <Controller
              id="vacancy-url"
              name="vacancy-url"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern:
                  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
              }}
              render={({ onChange, value }) => (
                <Input
                  autoFocus
                  type="text"
                  placeholder="e.g. https://gosympl.com/job/3456"
                  id="vacancy-url"
                  name="vacancy-url"
                  value={value}
                  onChange={(e) => onChange(e)}
                  onBlur={(e) => onChange(e)}
                />
              )}
            />
            <Error>
              {errors['vacancy-url'] && 'Please provide a valid url'}
            </Error>
          </div>

          <div tw="mt-4 w-full">
            <Label htmlFor="vacancy-brand">Brand*</Label>

            <Controller
              id="vacancy-brand"
              name="vacancy-brand"
              control={control}
              defaultValue={brands[0]?.id || null}
              render={({ onChange, value }: { onChange: any; value: any }) => (
                <>
                  <Dropdown
                    items={mappedBrands!}
                    value={value}
                    onChange={onChange}
                    defaultValue={brands[0]?.id}
                    onCreate={() => setBrandModalShown(true)}
                    createLabel="Create new brand"
                  />
                  <BrandModal
                    modalType={ModalType.ADD}
                    showModal={brandModalShown}
                    closeModal={() => setBrandModalShown(false)}
                    onClick={async (props) => {
                      createBrand({
                        variables: {
                          input: {
                            name: props?.brandName,
                          },
                        },
                      })
                        .then(() => refetchBrands())
                        .then(() => {
                          setBrandModalShown(false);
                          fireEvent('create_new_brand', {
                            brand: props?.brandName,
                          });
                          addToast({
                            type: ToastTypes.SUCCESS,
                            description: 'Brand created successfully.',
                          });
                        });
                    }}
                  />
                </>
              )}
            />
          </div>

          <div tw="w-full my-6">
            <Controller
              id="vacancy-enable-spend"
              name="vacancy-enable-spend"
              control={control}
              defaultValue={true}
              render={({ onChange, value }: { onChange: any; value: any }) => (
                <div tw="flex gap-4 items-center">
                  <Checkbox
                    id="spendEnabled"
                    name="spendEnabled"
                    checked={value}
                    onCheck={onChange}
                  />
                  <Label htmlFor="vacancy-enable-spend">
                    I want to advertise on social media
                  </Label>
                </div>
              )}
            />
          </div>
        </div>
        <Button type="submit" loading={loading} disabled={loading} stretch>
          Import Vacancy
        </Button>
        <div tw="mt-2">
          <Footnote>* fields are required</Footnote>
        </div>
      </form>
    </div>
  );
};

export default ImportCampaignForm;
