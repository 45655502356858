import React, { useCallback } from 'react';
import { CheckCircle } from '@phosphor-icons/react';
import tw, { styled } from 'twin.macro';
import useAdEditorContext from 'hooks/context/ad-editor-context';
import { getOverlay, OVERLAY_OPTIONS } from 'utils/overlayHelpers';
import useNavigationContext from 'hooks/context/nav-context';
import Button from 'components/button/Button';
import { fireEvent } from 'utils/eventHelper';
import { showIntercom } from 'utils/intercomHelper';

const OverlayPreview = ({ overlay }: { overlay: OVERLAY_OPTIONS }) => {
  const { currentVariant, updateCurrentVariant } = useAdEditorContext();
  const { currentVacancy } = useNavigationContext();

  const SVGComponent = useCallback(
    (overlay: OVERLAY_OPTIONS) => {
      return getOverlay(overlay, currentVariant, currentVacancy?.brand, true);
    },
    [
      currentVariant?.company,
      currentVariant?.function,
      currentVariant?.logo?.path,
      currentVariant?.previewImageElement,
    ]
  );
  return (
    <div
      css={[
        tw`relative flex cursor-pointer flex-col gap-4 rounded bg-gray-100`,
        currentVariant?.overlay === overlay && tw`ring-4 ring-green-400`,
      ]}
      onClick={() => updateCurrentVariant('overlay', overlay)}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: SVGComponent(overlay),
        }}
      />
      {currentVariant?.overlay === overlay && (
        <CheckCircle
          size={50}
          weight="fill"
          tw="absolute m-auto top-0 right-0 left-0 bottom-0 z-50 text-green-400"
        />
      )}
    </div>
  );
};

export const PlacementMenu = () => (
  <div>
    <PlacementMenuOptionContainer>
      {Object.values(OVERLAY_OPTIONS).map((value) => (
        <OverlayPreview key={value} overlay={value as OVERLAY_OPTIONS} />
      ))}
    </PlacementMenuOptionContainer>
    <div tw="flex flex-col items-center mt-8">
      <Button
        onClick={() => {
          fireEvent('custom_overlay_interested');
          showIntercom(
            'Hi, I want to use my own custom overlays. Help me out.'
          );
        }}
      >
        Add your own overlay
      </Button>
    </div>
  </div>
);

const PlacementMenuOptionContainer = styled.div(tw`grid grid-cols-2 gap-6`);
