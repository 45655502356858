import { Plus } from '@phosphor-icons/react';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

// Template styles
const template = `
    #header {
      background-color: var(--primary-color);
      color: white;
      padding: 100px 0;
      margin: 0 1%;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    #title, #job-description, #job-qualities {
      color: var(--secondary-color);
    }

    .section-heading {
      color: var(--tertiary-color);
    }

    #title {
      display: block;
      font-size: 44px;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      font-weight: bold;
    }

    .section-heading {
      font-size: 26px;
      line-height: 42px;
      margin: 0;
    }

    #job-description {
      font-size: 20px;
      line-height: 36px;
    }

    #job-qualities {
      line-height: 25px;
      font-size: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      margin-top: 2rem;
    }

    #job-qualities > div {
      display: flex;
      column-gap: 20px;
      width: 100%;
    }

    #job-qualities svg {
      height: 25px;
      width: 25px;
      text-align: center;
    }

    .card {
      box-shadow: 0 12px 18px -6px rgba(71, 74, 182, 0.12);
      padding: 30px 40px;
      background-color: white;
      font-size: 17px;
      line-height: 1.75;
    }

    #vertical-spacer {
      background-color: white;
      width: 10%;
      height: 3px;
      border: none;
      margin: 0;
    }

    .form-link {
      display: flex;
      margin: 3rem auto;
      text-align: center;
      width: fit-content;
    }

    .form-link a {
      font-size: 18px;
      line-height: 1;
      border-style: solid;
      border-width: 2px 2px 2px 2px;
      border-radius: 3px;
      padding: 12px 20px 12px 20px;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      border-radius: 10px;
      color: var(--secondary-color);
    }

    .form-link a:hover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--secondary-color);
      border-radius: 10px;
      opacity: 0.8;
    }

    .wrapper {
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;
      padding: 0% 5% 0% 5%;
    }

    #main {
      display: flex;
      flex-direction: column;
      column-gap: 3rem;
      margin-top: -70px;
      padding-bottom: 100px !important;
    }

    #side-article {
      font-size: 17px;
      min-width: 22rem;
    }

    #side-article article,
    #about {
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .img-container {
      width: 100%;
      display: flex;
    }

    .picture, #logo-img {
      height: auto;
      max-width: 100%;
    }

    .picture {
      max-height: 450px;
      width: auto;
      margin: 0 auto;
    }

    #logo-img {
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 150px;
      border-radius: 6px;
    }

    #logo-wrapper {
      width: 50%;
    }

    #side-widget {
      margin-top: 48px;
    }

    a:visited {
      text-decoration: none;
      color: var(--tertiary-color);
    }

    @media screen and (min-width: 1280px) {
      #main {
        flex-direction: row;
      }
      #side-article {
        max-width: 22rem;
      }
    }
  `;

// CSS reset overrides
const reset = tw`
    [p:not([data-tw])]:m-[revert]
    [h2]:[font-weight: revert]
    [.highlight ul]:[margin: revert]
    [section:not(#job-qualities) ol, section:not(#job-qualities) ul:not([data-tw])]:(list-[revert] p-[revert] m-[revert])
    [*:not([data-headlessui-state] *, .EmojiPickerReact *)]:[box-sizing: revert]
    [.EmojiPickerReact *]:[font-size:revert]
  `;

// Edit mode styles
const editMode = tw`
    font-wordpress
    [#logo-img, .picture]:cursor-pointer
    [#title]:font-wordpress
    [#main > div:not(#side-article)]:w-full
    [#job-description]:relative [#job-qualities]:relative
    [.card:not(:first-of-type)]:bg-transparent
    [.card]:relative
    [.section-heading]:min-w-full
    [.EmojiPickerReact]:(scale-[88.5%] origin-top-left)
    [.epr-emoji-category-label]:text-lg
    [div:has(> .EmojiPickerReact)]:(h-[400px] w-[312px])

    // ring-offset-color must match background (https://tailwindcss.com/docs/ring-offset-color#setting-the-ring-offset-color)
    [.highlight:not(.section-heading):hover]:(ring-4 ring-blue-500 ring-offset-8 ring-offset-[var(--primary-color)] rounded cursor-pointer bg-[var(--primary-color)])
    [.highlight:not(.section-heading):focus]:(ring-4 ring-blue-500 ring-offset-8 ring-offset-[var(--primary-color)] rounded cursor-pointer bg-[var(--primary-color)])
    [.highlight-white:hover]:(ring-4 ring-blue-500 ring-offset-8 ring-offset-white rounded cursor-pointer bg-white)
    [.highlight-white:focus]:(ring-4 ring-blue-500 ring-offset-8 ring-offset-white rounded cursor-pointer bg-white)
    [.highlight[contenteditable="true"]:hover]:cursor-text
    [.highlight-white[contenteditable="true"]:hover]:cursor-text

    [.highlight:not(.section-heading):focus]:outline-none
    [.highlight-white:focus]:outline-none
  `;

export const Section = styled.section<{
  cssVariables: string;
}>`
  ${({ cssVariables }) => cssVariables}
  ${css`
    ${template}
    ${reset}
    ${editMode}
  `}
`;

export const TrashIcon = ({
  isgenerating,
  onClick,
}: {
  isgenerating: boolean;
  onClick: () => void;
}) => {
  return (
    <div onClick={onClick}>
      <RemoveIcon isgenerating={isgenerating} />
    </div>
  );
};

const TrashSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="currentColor"
    // cursor={isgenerating ? 'wait' : 'pointer'}
    viewBox="0 0 256 256"
  >
    <path d="M216,48H180V36A28,28,0,0,0,152,8H104A28,28,0,0,0,76,36V48H40a12,12,0,0,0,0,24h4V208a20,20,0,0,0,20,20H192a20,20,0,0,0,20-20V72h4a12,12,0,0,0,0-24ZM100,36a4,4,0,0,1,4-4h48a4,4,0,0,1,4,4V48H100Zm88,168H68V72H188ZM116,104v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Zm48,0v64a12,12,0,0,1-24,0V104a12,12,0,0,1,24,0Z"></path>
  </svg>
);

export const SymbolWrapper = styled.div(
  tw`absolute top-0 right-auto left-[16px]`
);

const RemoveIcon = styled(TrashSVG)<{ isgenerating: boolean }>`
  ${tw`text-gray-300 ml-auto min-w-[2rem] hover:text-gray-400 focus:text-gray-400`}
  ${({ isgenerating }) =>
    isgenerating
      ? tw`cursor-wait`
      : tw`cursor-pointer hover:text-gray-400 focus:text-gray-400`}
`;

export const PlusIcon = styled(Plus)(tw`text-2xl font-bold mx-auto`);
