import React, { useMemo, useRef, useState, useEffect } from 'react';
import { CaretUpDown, Plus, X, Check } from '@phosphor-icons/react';
import tw, { css, styled, TwStyle } from 'twin.macro';

import useDefocusHandler from 'hooks/defocus';
import { useFloating, autoUpdate, flip } from '@floating-ui/react-dom';

export interface DropdownProps {
  label?: string;
  stretch?: boolean;
  fullHeight?: boolean;
  value?: number | string;
  items: DropdownItem[];
  loading?: boolean;
  searchable?: boolean;
  icon?: React.ReactNode;
  insideControls?: boolean;
  disabled?: boolean;
  enableCustom?: boolean; // Triggers onChange on blur
  minWidth?: boolean; // w-min
  customValueType?: string;
  allowCustomValue?: boolean;
  noResultsLabel?: string;
  clearFilterButton?: boolean;
  showIcon?: boolean;
  defaultValue?: string | number;
  customStyle?: TwStyle;
  isMultiSelect?: boolean;
  selectedItems?: DropdownItem[];
  title?: string;
  quickActions?: React.ReactNode;
  onCreate?: () => void;
  createLabel?: string;
  customLabelRenderer?: (arg: string | number) => React.ReactNode;
  onChange?: (key: number | string) => unknown;
  onSearchChange?: (value: string) => void;
  onBlur?: (key: number | string) => void;
  onClear?: () => void;
  onIsOpen?: (isOpen: boolean) => void;
}

export interface DropdownItem {
  key: number | string;
  label: string;
  subLabel?: string;
  labelIcon?: React.ReactNode;
  deleted?: boolean;
  customStyle?: object;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  loading,
  items: _items,
  icon,
  insideControls = false,
  value,
  searchable = true,
  disabled = false,
  stretch = true,
  fullHeight = false,
  enableCustom = false,
  minWidth = false,
  customValueType = 'item',
  allowCustomValue = false,
  noResultsLabel = 'No results found',
  clearFilterButton = false,
  showIcon = true,
  customStyle,
  isMultiSelect,
  selectedItems,
  quickActions,
  title,
  customLabelRenderer,
  onChange,
  defaultValue,
  onBlur,
  onClear,
  onSearchChange,
  onIsOpen,
  onCreate,
  createLabel,
}) => {
  const { y, strategy, refs } = useFloating({
    strategy: insideControls ? 'absolute' : 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  if (enableCustom && !searchable) {
    throw new Error(
      'Dropdown has to have search enabled to be able to enable custom input.'
    );
  }

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(value?.toString());
  const [lastSelectedItem, setLastSelectedItem] = useState<string>('');

  useEffect(() => {
    setFilter('');
    setLastSelectedItem(label ?? '');
  }, [label]);

  useEffect(() => onIsOpen?.(isOpen), [isOpen, onIsOpen]);

  useDefocusHandler(wrapperRef, () => {
    setIsOpen(false);
    inputRef?.current?.blur();
  });

  const searchInputFocusHandler = () => {
    setIsOpen(!isOpen);
  };

  const items = useMemo(
    () =>
      allowCustomValue && filter
        ? [
            {
              key: filter,
              label: `Create new ${customValueType}: ${filter}`,
            },
            ..._items,
          ]
        : _items,
    [_items, allowCustomValue, customValueType, filter]
  );
  useEffect(() => {
    if (!defaultValue) return;
    const defaultV = items.find(({ key }) => key === defaultValue)?.label;
    setFilter('');
    setLastSelectedItem(defaultV ?? items[0]?.label ?? '');
    inputRef?.current?.blur();
  }, [defaultValue, items]);

  const itemChangeHandler = (key: number | string) => {
    const newLabel = items.find((item) => item.key === key)?.label;

    if (!newLabel) return;

    onChange?.(key);
    if (!isMultiSelect) setIsOpen(false);
    setFilter('');
    setLastSelectedItem(newLabel);
    inputRef?.current?.blur();
  };

  const clearHandler = () => {
    setFilter('');
    setLastSelectedItem(label ?? '');
    onClear?.();
  };

  const blurHandler = () => {
    if (enableCustom && filter) onChange?.(filter);
    onBlur?.(filter ?? '');
    if (filter) setLastSelectedItem(filter);
  };

  const filteredItems = useMemo(
    () =>
      items?.filter(
        (item) =>
          !searchable ||
          !filter ||
          item?.label
            ?.toLowerCase()
            .replaceAll(',', '') // Google address results contain commas
            ?.includes(filter?.toLowerCase()) // Ideally we should use a fuzzy search here
      ),
    [filter, items, searchable]
  );

  const inputValue = value && value !== '' ? value.toString() : undefined;

  const getLabelValue = () => {
    if (customLabelRenderer) {
      return customLabelRenderer(label ?? items?.[0]?.key);
    }
    return <span tw="pr-8 min-h-[20px]">{label ?? items?.[0]?.key}</span>;
  };

  const getInputValue = () => {
    if (filter === '') return '';
    const inputFilter = filter && filter !== '' ? filter : undefined;
    return inputFilter ?? inputValue ?? '';
  };

  const getInputPlaceholder = () =>
    !isOpen ? (isMultiSelect ? title : lastSelectedItem ?? label) : undefined;

  if (loading) {
    return (
      <div tw="relative flex items-center border bg-gray-200 h-10 w-full animate-pulse rounded-md">
        <CaretUpDown
          size="24"
          weight="bold"
          tw="ml-1 absolute right-2 text-gray-400"
        />
      </div>
    );
  }

  const dropDownStylingOptions = {
    position: strategy,
    top: (y ?? 0) - (insideControls ? 16 : 0),
    left: insideControls
      ? 0
      : refs.reference.current?.getBoundingClientRect().x ?? 0,
    minWidth: refs.reference.current?.getBoundingClientRect().width,
    width: 'max-content',
  };

  return (
    <Wrapper
      ref={wrapperRef}
      hasFocus={isOpen}
      stretch={stretch}
      fullHeight={fullHeight}
      translate="no"
    >
      {searchable ? (
        <InputWrapper ref={refs.setReference}>
          <div tw="flex justify-between items-center w-full h-full">
            <div
              tw="flex items-center w-full h-full"
              onClick={searchInputFocusHandler}
            >
              {icon && <div tw="ml-4">{icon}</div>}
              <Input
                ref={inputRef}
                type="text"
                value={getInputValue()}
                placeholder={getInputPlaceholder()}
                onChange={(e) => {
                  setFilter(e.target.value);
                  onSearchChange?.(e.target.value);
                }}
                onBlur={blurHandler}
                disabled={disabled}
                size={
                  minWidth
                    ? Math.max(...items.map((item) => item.label.length)) + 2
                    : undefined
                }
              />
            </div>
            {!disabled && showIcon && (
              <div tw="flex justify-center items-center cursor-pointer absolute right-2 top-2 gap-1 text-gray-400">
                {value && onClear && <X size={16} onClick={clearHandler} />}
                <CaretUpDown size={24} onClick={searchInputFocusHandler} />
              </div>
            )}
          </div>
        </InputWrapper>
      ) : (
        <Button
          disabled={disabled}
          id="options-menu"
          ref={refs.setReference}
          type="button"
          aria-haspopup="true"
          aria-expanded="true"
          onFocus={searchInputFocusHandler}
          insideControls={insideControls}
        >
          {icon && <div tw="mr-3">{icon}</div>}
          {getLabelValue()}
          <CaretUpDown size="24" tw="ml-1 absolute right-2" />
        </Button>
      )}

      {isOpen && (
        <div
          ref={refs.setFloating}
          style={dropDownStylingOptions}
          data-html2canvas-ignore
          tw="flex flex-col h-fit relative max-h-80 mt-2 z-[100] rounded-md shadow-lg bg-white ring-2 ring-black/5"
        >
          <div tw="w-full z-[100] overflow-y-auto max-h-72 origin-top-right">
            <DropDownItemList
              customStyle={customStyle ?? tw``}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {onCreate && (
                <DropdownListItem onClick={onCreate}>
                  <Plus weight="bold" tw="text-indigo-500" />
                  <p data-tw="do not remove this" tw="text-indigo-500">
                    {createLabel ?? `Create new ${customValueType}`}
                  </p>
                </DropdownListItem>
              )}
              {clearFilterButton &&
                lastSelectedItem &&
                lastSelectedItem !== label && (
                  <DropdownListItem onClick={clearHandler}>
                    <X weight="bold" />
                    <p data-tw="do not remove this">Clear filter</p>
                  </DropdownListItem>
                )}
              {filteredItems?.length ? (
                filteredItems.map((item, index) => (
                  <DropdownListItem
                    isMultiSelect
                    tabIndex={0}
                    onClick={() => itemChangeHandler(item.key)}
                    key={index}
                    role="menuitem"
                  >
                    <div>
                      {allowCustomValue && filter && index === 0 && <Plus />}
                      <div tw="flex items-center gap-1">
                        {item.labelIcon && (
                          <div tw="mr-2">{item.labelIcon}</div>
                        )}
                        <div tw="flex flex-col gap-0.5">
                          <p
                            css={[
                              tw`truncate text-sm font-medium`,
                              item.deleted && tw`text-gray-200`,
                            ]}
                            style={item.customStyle}
                          >
                            {item.label}
                          </p>

                          {item.subLabel && (
                            <p css={tw`truncate text-xs text-gray-500`}>
                              {item.subLabel}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {isMultiSelect &&
                      selectedItems?.find((i) => i.key === item.key) && (
                        <Check
                          className="selectedCheck"
                          weight="bold"
                          size={20}
                          tw="text-indigo-500"
                        />
                      )}
                    {isMultiSelect &&
                      selectedItems?.find((i) => i.key === item.key) ===
                        undefined && (
                        <Check
                          className="hoverCheck"
                          weight="bold"
                          size={20}
                          tw="text-gray-300"
                        />
                      )}
                  </DropdownListItem>
                ))
              ) : (
                <li tw="px-4 py-2 text-gray-500 text-sm">{noResultsLabel}</li>
              )}
            </DropDownItemList>
          </div>
          {quickActions && <div tw="p-2 h-full w-full">{quickActions}</div>}
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  hasFocus: boolean;
  stretch: boolean;
  fullHeight: boolean;
}>`
  ${tw`relative inline-block text-left rounded border-none`}

  ${({ stretch }) => stretch && tw`w-full`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
  ${({ hasFocus }) => hasFocus && tw`outline-none outline outline-blue-500`}
`;

const Button = styled.button<{ insideControls: boolean }>`
  ${tw`
    flex text-sm font-medium text-gray-700 outline-none rounded
    items-center w-full h-full relative ring-1 ring-black/5
  `}
  ${({ insideControls }) => (insideControls ? tw`px-3 py-2 h-[33px]` : tw`p-3`)}
`;

const InputWrapper = styled.div(
  tw`
    flex items-center w-full relative cursor-text h-full
    text-sm font-medium text-gray-700 bg-gray-50 rounded
  `
);

const Input = styled.input<{ disabled: boolean }>`
  ${tw`
    flex-1 text-sm font-medium text-gray-700 p-3 outline-none
    rounded placeholder-gray-700/100 overflow-x-hidden cursor-pointer
    block w-full ring-1 border-none ring-black/5 h-full
    placeholder-shown:(truncate pr-6 lg:pr-3)
  `}

  ${({ disabled }) =>
    disabled &&
    tw`bg-gray-50 text-gray-500 placeholder:text-gray-500 cursor-not-allowed`}
`;

const DropDownItemList = styled.ul<{ customStyle: TwStyle }>`
  ${tw`py-1`}
  ${({ customStyle }) => customStyle}
`;

const DropdownListItem = styled.li<{ isMultiSelect?: boolean }>(
  tw`
    flex place-items-center space-x-1 px-4 cursor-pointer py-2 text-sm text-gray-700
    hover:(bg-gray-100 text-gray-800 font-medium) transition-colors
  `,
  ({ isMultiSelect }) => isMultiSelect && tw`justify-between items-center`,
  css`
    .hoverCheck {
      display: none;
    }
    &:hover .hoverCheck {
      display: block;
    }
  `
);

export default Dropdown;
