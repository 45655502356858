import { ContactKeyFields } from '@symplbe/sympl-components';
import { WithPlaceholder } from 'types/careers-page/section';
import { getSectionHeaderDefaultConfig } from '../shared/defaultConfig';

export const defaultContactSettings: ContactKeyFields & WithPlaceholder = {
  ...getSectionHeaderDefaultConfig(),
  cards: [],
  subTitle: 'Contact',
  title: 'Get in touch!',
};
