import React, { useRef } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import SymplLogo from 'assets/sympl-logo-cropped.png';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdEditorPreviewProps } from 'components/ad-builder/Preview';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import EditableInput from 'components/form/editable-input/EditableInput';
import Img from 'components/image/Image';
import { DotsThreeVertical, Camera } from '@phosphor-icons/react';
import { CTALabel } from 'data/adEditorCTA';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useNavigationContext from 'hooks/context/nav-context';

const Preview: React.FC<AdEditorPreviewProps> = ({ id }) => {
  const { currentVariant, uploadMode, setActiveTab, updateCurrentVariant } =
    useAdEditorContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const { currentVacancy } = useNavigationContext();

  return (
    <Container id={id} ref={containerRef}>
      <TopSection>
        <StoryOptions>
          <Camera weight="bold" size={30} />
        </StoryOptions>
      </TopSection>
      <StoryCanvasWrapper>
        <AdEditorVideoContainer />
      </StoryCanvasWrapper>

      <FooterSection>
        <div tw="flex flex-col w-[85%]">
          <Header>
            <BrandImage>
              <Img
                src={currentVacancy?.brand?.facebookPage?.logo_url ?? SymplLogo}
                alt={'instragram-story-brand-logo'}
                tw="w-full h-full"
              />
            </BrandImage>
            <div tw="flex-col text-black text-xs">
              <BrandName tw="text-white">
                {currentVacancy?.brand?.facebookPage?.name ?? 'sympl'}
              </BrandName>
              <div tw="text-gray-500">Sponsored</div>
            </div>
          </Header>
          <div tw="mt-1 mb-2">
            <EditableInput
              id="text"
              key={currentVariant?.text ?? 'text'}
              defaultValue={currentVariant?.text}
              placeholder={'Looking for a cool job? Xyz got you covered!'}
              onChange={(value) => updateCurrentVariant('text', value)}
              editorRows={2}
              style={{
                color:
                  currentVariant?.path && !uploadMode
                    ? 'white'
                    : theme`colors.gray.500`,
                fontSize: '0.875rem',
                wordWrap: 'break-word',
                backgroundColor: 'transparent',
              }}
              styleWrapper={[
                tw`
                    hover:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                    focus:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                  `,
              ]}
            >
              {currentVariant?.text}
            </EditableInput>
          </div>
          <Button
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            {currentVariant?.cta || CTALabel.SIGN_UP}
          </Button>
        </div>
        <div tw="w-fit">
          <ActionsBar>
            <Action>
              <svg
                color="rgb(245, 245, 245)"
                fill="rgb(245, 245, 245)"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path>
              </svg>
              1572
            </Action>
            <Action>
              <svg
                color="rgb(245, 245, 245)"
                fill="rgb(245, 245, 245)"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
              >
                <title>Comment</title>
                <path
                  d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z"
                  fill="none"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </svg>
              256
            </Action>
            <Action>
              <svg
                color="rgb(245, 245, 245)"
                fill="rgb(245, 245, 245)"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
              >
                <line
                  fill="none"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  x1="22"
                  x2="9.218"
                  y1="3"
                  y2="10.083"
                ></line>
                <polygon
                  fill="none"
                  points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></polygon>
              </svg>
              68
            </Action>
            <Action>
              <DotsThreeVertical weight="bold" size={25} />
            </Action>
          </ActionsBar>
        </div>
      </FooterSection>
    </Container>
  );
};

const Container = styled.div`
  ${tw`
  text-white relative rounded-xl overflow-hidden shadow-md w-[23rem] sm:w-96 max-[400px]:(w-[95vw])
`}
  ${css`
    aspect-ratio: 9/16;

    #description {
      color: white !important;
      pointer-events: auto;
    }

    #description:focus {
      color: black !important;
    }
  `}
`;

const StoryCanvasWrapper = styled.div(tw`rounded-b-md h-full overflow-hidden`);

const FooterSection = styled.div(
  tw`flex flex-row items-end justify-between p-3 absolute w-full -bottom-10 z-30 pb-16`
);

const TopSection = styled.div(tw`p-3 absolute top-0 w-full z-30`);

const Header = styled.div(tw`flex place-items-center gap-3 items-center mt-3`);

const BrandImage = styled.picture(
  tw`overflow-hidden rounded-full flex relative cursor-pointer h-8 w-8`
);

const BrandName = styled.p(
  tw`font-semibold text-xs leading-tight cursor-pointer`
);

const Button = styled.div(
  tw`py-2 text-black text-center font-semibold text-sm mr-5 bg-white rounded-md cursor-pointer hover:(border-2 border-indigo-400 )`
);

const ActionsBar = styled.div(
  tw`flex flex-col gap-y-3 items-center text-center justify-center`
);

const Action = styled.div(
  tw`text-white text-sm flex flex-col items-center justify-center`
);

const StoryOptions = styled.div(tw`absolute right-5 top-5`);

export default Preview;
