import { faker } from '@faker-js/faker';
import { capitalize } from 'lodash';

interface SectionHeaderDefaultConfig {
  subTitle: string;
  title: string;
  description: string;
  primaryButtonLabel: string;
  linkButtonLabel: string;
  placeholderValue: {
    subTitle: string;
    title: string;
    description: string;
    primaryButtonLabel: string;
    linkButtonLabel: string;
  };
}

export const getSectionHeaderDefaultConfig = (
  paragraphs = 1
): SectionHeaderDefaultConfig => {
  return {
    subTitle: '',
    title: '',
    description: '',
    primaryButtonLabel: '',
    linkButtonLabel: '',
    placeholderValue: {
      //subTitle: capitalize(faker.lorem.words(2)),
      subTitle: capitalize(
        faker.company.buzzVerb() + ' ' + faker.company.buzzNoun()
      ),
      //title: capitalize(faker.lorem.words(5)),
      title: capitalize(faker.company.buzzPhrase()),
      description: faker.lorem.paragraphs(paragraphs),
      primaryButtonLabel: 'Check our open positions',
      linkButtonLabel: 'Contact us',
    },
  };
};
