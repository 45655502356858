import React, { useRef } from 'react';
import tw, { css, styled, theme } from 'twin.macro';
import SymplLogo from 'assets/sympl-logo-cropped.png';
import { Chat, ShareFat, ThumbsUp } from '@phosphor-icons/react';

import useAdEditorContext from 'hooks/context/ad-editor-context';
import { AdEditorPreviewProps } from 'components/ad-builder/Preview';
import AdEditorVideoContainer from 'components/ad-builder/VideoContainer';
import EditableInput from 'components/form/editable-input/EditableInput';
import Img from 'components/image/Image';
import { CTALabel } from 'data/adEditorCTA';
import { AdEditorSideBarTabs } from 'views/ad-builder/AdBuilder';
import useNavigationContext from 'hooks/context/nav-context';

const Preview: React.FC<AdEditorPreviewProps> = ({ id }) => {
  const { currentVariant, updateCurrentVariant, setActiveTab, uploadMode } =
    useAdEditorContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const { currentVacancy } = useNavigationContext();

  return (
    <Container id={id} ref={containerRef}>
      <TopSection>
        <Header>
          <BrandImage>
            <Img
              src={currentVacancy?.brand?.facebookPage?.logo_url ?? SymplLogo}
              alt={'facebook-story-brand-logo'}
              tw="w-full h-full"
            />
          </BrandImage>
          <div tw="flex-col text-black text-xs">
            <BrandName tw="text-white">
              {currentVacancy?.brand?.facebookPage?.name ?? 'sympl'}
            </BrandName>
            <div tw="text-gray-500">Sponsored</div>
          </div>
        </Header>
      </TopSection>
      <CanvasWrapper>
        <AdEditorVideoContainer />
      </CanvasWrapper>

      <FooterSection>
        <div tw="flex flex-col w-[85%]">
          <div tw="mt-1 mb-2">
            <EditableInput
              id="text"
              key={currentVariant?.text ?? 'text'}
              defaultValue={currentVariant?.text}
              placeholder={'Looking for a cool job? Xyz got you covered!'}
              onChange={(value) => updateCurrentVariant('text', value)}
              editorRows={2}
              style={{
                color:
                  currentVariant?.path && !uploadMode
                    ? 'white'
                    : theme`colors.gray.500`,
                fontSize: '0.875rem',
                wordWrap: 'break-word',
                backgroundColor: 'transparent',
              }}
              styleWrapper={[
                tw`
                    hover:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                    focus:(ring-2 ring-indigo-400 bg-indigo-50/25 rounded-sm)
                  `,
              ]}
            >
              {currentVariant?.text}
            </EditableInput>
          </div>
          <Button
            onClick={() => setActiveTab?.(AdEditorSideBarTabs.BANNER_OPTIONS)}
          >
            {currentVariant?.cta || CTALabel.SIGN_UP}
          </Button>
        </div>
        <div tw="w-fit">
          <ActionsBar>
            <Action></Action>
            <Action>
              <ThumbsUp weight="fill" size={25} />
              1572
            </Action>
            <Action>
              <Chat weight="fill" size={25} />
              256
            </Action>
            <Action>
              <ShareFat weight="fill" size={25} />
              89
            </Action>
          </ActionsBar>
        </div>
      </FooterSection>
    </Container>
  );
};

const Container = styled.div`
  ${tw`
  text-white relative rounded-xl overflow-hidden shadow-md w-[23rem] sm:w-96 max-[400px]:(w-[95vw])
`}
  ${css`
    aspect-ratio: 9/16;

    #description {
      color: white !important;
      pointer-events: auto;
    }

    #description:focus {
      color: black !important;
    }
  `}
`;

const CanvasWrapper = styled.div(tw`rounded-b-md h-full overflow-hidden`);

const FooterSection = styled.div(
  tw`flex flex-row items-end justify-between p-3 absolute w-full -bottom-10 z-30 pb-16`
);

const TopSection = styled.div(tw`p-3 absolute top-0 w-full z-30`);

const Header = styled.div(tw`flex place-items-center gap-3 items-center mt-3`);

const BrandImage = styled.picture(
  tw`overflow-hidden rounded-full flex relative cursor-pointer h-8 w-8`
);

const BrandName = styled.p(
  tw`font-semibold text-xs leading-tight cursor-pointer`
);

const Button = styled.div(
  tw`py-2 text-black text-center font-semibold text-sm mr-5 bg-white rounded-md cursor-pointer hover:(border-2 border-indigo-400 )`
);

const ActionsBar = styled.div(
  tw`flex flex-col gap-y-3 items-center text-center justify-center`
);

const Action = styled.div(
  tw`text-white text-sm flex flex-col items-center justify-center`
);

export default Preview;
